import React, {useContext} from "react";
import {createStyles, withStyles} from "@material-ui/core";
import I18nMessage from "./i18n/I18nMessage";
import {IMetaTaxonomy} from "@jorsek/content-portal-client/types"
import {I18nContext} from "../i18n/index";
import {getLocalizedTaxonomyLabel} from "../utils/TaxonomyLabelUtil";

const metadataStyles = createStyles((theme: any) => ({
    hidePrint: theme.hidePrint,
    metadata: theme.metadata,
    metadataItem: theme.metadataItem,
    metadataLastModified: theme.metadataLastModified,
}))

interface IMetadataProps {
    classes?: any,
    standardMetadata?: any,
    locale: string,
    customMetadata?: any
    dateFormat?: any
}

const Metadata = ({customMetadata, standardMetadata, classes, locale, dateFormat}: IMetadataProps) => {
    const i18nContext = useContext(I18nContext)
    let lastModified  
    if (standardMetadata && standardMetadata.date && standardMetadata.date.lastModified) {
        const {value} = standardMetadata.date.lastModified;
        lastModified = new Date(+value).toLocaleDateString(locale || "en-US", dateFormat);
    }
    return (
        <>
            <div className={`${classes.metadata} ${classes.hidePrint}`}>
                {customMetadata ? Object.entries((customMetadata.taxonomy || [])).map(([_key, value]) => (value as IMetaTaxonomy).values).flat().map(value =>
                    {
                        const label = getLocalizedTaxonomyLabel(i18nContext, value.value, value.humanReadable);
                        return <div key={value.humanReadable}
                                    className={classes.metadataItem}>
                            {label}
                        </div>
                    }) : null
                }
            </div>
            {lastModified ? (<I18nMessage id={"label.last-updated"}
                                          className={classes.metadataLastModified}
                                          values={{date: lastModified}}/>) : null}
        </>
    );
}

Metadata.defaultProps = {
    standardMetadata: {},
    customMetadata: {},
    lastModified: null,
    dateFormat: {year: 'numeric', month: 'long', day: 'numeric'},
    disableLocaleDate: false

}

export default withStyles(metadataStyles, {name: "Metadata"})(Metadata)