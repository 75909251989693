import React from "react";
import {createStyles, Drawer, withStyles, withTheme} from "@material-ui/core";
import {withRouter} from "next/router";
import {WithRouterProps} from "next/dist/client/with-router";
import VersionSelector from "../../components/VersionSelector";
import {INavTree, IPageContent} from "@jorsek/content-portal-client/types";
import SocialSharing from "../../components/SocialSharing";
import NavTree from "../../components/NavTree";
import PortalBreadcrumb from "../../components/PortalBreadcrumb";
import getContentProps from "../../utils/getContentProps";
import Layout from "../../components/Layout"
import {scroller} from "react-scroll";
import {template} from "@jorsek/content-portal-utils";
import {I18nContext} from "../../i18n/index";
import I18nMessage from "../../components/i18n/I18nMessage";
import RenderHtml from "../../components/RenderHtml";
import SimpleTree from "../../components/SimpleTree";
import clsx from 'clsx';
import RelatedLinks from "../../components/RelatedLinks";
import StepLinks from "../../components/StepLinks";
import PortalFontIcon from "../../components/PortalFontIcon";
import ShowContents from "../../components/ShowContents";
import ContentIndex from "./index";
import Metadata from "../../components/Metadata";
import ContentMeta from "../../components/ContentMeta";
import * as path from "path";
import {checkAuth} from "../../utils/auth";
import {findFirstTopicRef, findStart, pruneInitialTOC, removeAtDepth} from '../../utils/tree';
import DataManager from "../../utils/dataManager";
import HerettoDeployWindowObj, {ClientEvent} from "../../components/HerettoDeployWindowObj";
import {assignSsoInfoProp} from "../../utils/PropUtil";
import {getClientSideConfig, handleForceStatusCode, handlePageError} from "../../utils/PageUtil";
import ErrorPage from "../error";
import '@jorsek/elements-core/styles-isolated.min.css';
import OpenApiContentViewer from "../../components/openapi/OpenApi";

const contentPageStyles = createStyles((theme: any) => ({
    hidePrint: theme.hidePrint,
    contentPage: theme.contentPage,
    contentPageContainer: theme.contentPageContainer,
    contentPageSiteSectionTitle: theme.contentPageSiteSectionTitle,
    contentPageSiteSectionIcon: theme.contentPageSiteSectionIcon,
    contentPageBottomStepLinks: theme.contentPageBottomStepLinks,
    contentPageNavBar: theme.contentPageNavBar,
    contentPageTocContainer: theme.contentPageTocContainer,
    contentPageToc: theme.contentPageToc,
    contentPageTocHeader: theme.contentPageTocHeader,
    contentPageTocHeaderContainer: theme.contentPageTocHeaderContainer,
    contentPageChunkedSections: theme.contentPageChunkedSections,
    contentPageChunkedSectionsSimpleTree: theme.contentPageChunkedSectionsSimpleTree,
    contentPageChunkedSectionsWrapper: theme.contentPageChunkedSectionsWrapper,
    contentPageChunkedSectionsHeader: theme.contentPageChunkedSectionsHeader,
    contentPageChunkedSectionsTitle: theme.contentPageChunkedSectionsTitle,
    contentPageChunkedSectionsCloseIcon: theme.contentPageChunkedSectionsCloseIcon,
    contentPageTocTitle: theme.contentPageTocTitle,
    contentPageTocSubtitle: theme.contentPageTocSubtitle,
    contentPageHeaderBreadcrumb: theme.contentPageHeaderBreadcrumb,
    contentPageMainHeader: theme.contentPageMainHeader,
    contentPageMainWrapper: theme.contentPageMainWrapper,
    contentPageMainContainer: theme.contentPageMainContainer,
    contentPageMain: theme.contentPageMain,
    contentPageMainRendered: theme.contentPageMainRendered,
    contentPageMainRenderedMissing: theme.contentPageMainRenderedMissing,
    contentPageDrawer: theme.contentPageDrawer,
    contentPageDrawerClose: theme.contentPageDrawerClose,
    contentPageDrawerOpen: theme.contentPageDrawerOpen,
    contentPagePrintButton: theme.contentPagePrintButton,
    contentPageShareButton: theme.contentPageShareButton,
    contentPageSiteSectionIconWrapper: theme.contentPageSiteSectionIconWrapper,
    
    "@global": {
        ".ezd-portal_content-page_main .elementsCoreContainer .sl-text-lg": {
            "min-width": "50px",
            "text-align": "center",
            "font-size": "12px",
            "padding-left": "0",
            "padding-right": "0"
        },
        ".ezd-portal_content-page_main .elementsCoreContainer .sl-stack > .sl-relative": {
            "margin-top": "5px"
        },
        ".ezd-portal_content-page_main .elementsCoreContainer .sl-text-sm": {
            "font-size": "12px"
        },
        ".ezd-portal_content-page_main .elementsCoreContainer .sl-text-danger-light": {
            "color": "rgb(254, 120, 115)"
        },
        ".ezd-portal_content-page_main .elementsCoreContainer .sl-border-danger": {
            "border-color": "rgb(254, 120, 115)"
        },
        ".ezd-portal_content-page_main .elementsCoreContainer .sl-flex .sl-flex-grow .sl-text-lg": {
            "display": "flex",
            "flex-direction": "row",
            "flex-wrap": "wrap"
        },
        ".ezd-portal_content-page_main .elementsCoreContainer .sl-flex .sl-flex-grow .sl-text-lg .sl-px-2": {
            "margin-left": "8px",
            "font-weight": "600"
        }
    }
}));

export interface IContentPageProps {
    runtimeConfig: any;
    layoutProps: any;
    content: any;
    navtree: INavTree;
    structure: any;
    siteSections: any[];
    treePath: string;
    contentSubPath: string;
    section: string;
    version?: string;
    classes?: any;
    theme?: any;
    showErrorPage?: boolean;
}

class ContentPage extends React.Component<WithRouterProps & IContentPageProps, any> {
    constructor(props) {
        super(props)
        this._fetchPromises = [];
        this.state = {
            ...this.state,
            ...props
        }
    }

    private _asPath: string;
    private _fetchPromises: any[];
    static contextType = I18nContext;
    static defaultProps = {
        content: {
            breadcrumbs: [{}, {}, {type: "version", title: ""}],
            parent: {},
            previous: {},
            next: {},
            children: [],
            relatedLinks: [],
            customMetadata: {taxonomy: {}}
        } as IPageContent,
        siteSections: [],
        section: "",
        treePath: "",
    }

    state = {
        displayTiles: null,
        // filteredToc: null,
        content: null,
        treePath: null,
        contentPath: null,
        contentSubPath: null,
        structure: null,
        section: null,
        version: null,
        drawerOpen: false,
        chunkedOpen: false
    };

    async fetchApiContent() {
        let [pathWithoutAnchor, anchor = ""] = this.props.router.asPath.split("#");
        let [contentPath, search = ""] = pathWithoutAnchor.split("?");
        if (this.props.runtimeConfig.showContentsNav !== false) {
            search += '&add-headers=true'
        }
        const cPath = `${path.join('/api/content', contentPath)}?locale=${this.props.router.locale}`;
        return await fetch(`${cPath}${search ? `&${search}` : ""}`)
        .then(response => response.json())
    }

    async fetchApiStructure(treePath) {
        let [, search = ""] = this.props.router.asPath.split("?");
        const sPath = `${path.join('/api/structure', treePath)}?locale=${this.props.router.locale}`;
        return await fetch(`${sPath}${search ? `&${search}` : ""}`)
        .then(response => response.json())
    }

    async loadContent() {
        if (this._fetchPromises.length) return;
        this._asPath = this.props.router.asPath;
        this._fetchPromises = [];
        const stateObj = {
            displayTiles: this.props.content && this.props.content.displayTiles,
            treePath: this.props.treePath,
            contentPath: this._asPath.replace(/^\//, ''),
            contentSubPath: this._asPath.replace(this.props.treePath, ''),
            drawerOpen: false
        } as any;
        let structureShouldChange = (this.state.treePath !== this.props.treePath) ||
            (this.state.displayTiles !== stateObj.displayTiles);
        if (structureShouldChange || !this.state.structure) {
            stateObj.treePath = this.props.treePath;
            this._fetchPromises.push(this.fetchApiStructure(this.props.treePath).then((structure) => {
                if (structure) {
                    stateObj.structure = structure;
                }
                return structure;
            }))
        }
        if (structureShouldChange || (this.state.contentSubPath !== stateObj.contentSubPath)) {
            this._fetchPromises.push(this.fetchApiContent().then((content) => {
                if (content) {
                    stateObj.content = content;
                }
                return content;
            }));
        }
        return Promise.all(this._fetchPromises).then(() => {
            this.setState(stateObj);
            const pageData = DataManager.get('pageData');
            DataManager.set('pageData', {
                ...pageData,
                structure: this.state.structure,
                content: this.state.content,
            })
            this._fetchPromises = [];
            //just push the scrolling to the end of the queue since rendering/painting is synchronous anyways.
            setTimeout(() => this.scrollTOC());
            setTimeout(() => this.scrollToSection());
            
            const isOpenAPi = !!this.state.content.apiOperationMeta
            if(!isOpenAPi){
                setTimeout(() => HerettoDeployWindowObj.triggerEvent(ClientEvent.pageUpdate));
            }
        });
    }

    async componentDidUpdate() {
        if (this._asPath !== this.props.router.asPath) {
            return this.loadContent();
        }
    }

    async componentDidMount() {
        if (!this.props.showErrorPage) {
            this.fetchApiStructure(this.props.treePath).then((structure) => {
                this.setState({...this.props, ...this.state, structure})
            })
            console.log("Sttructure downloaded")
            return this.loadContent()
        }
    }

    scrollTOC() {
        scroller && scroller.scrollTo(this.state.contentPath, {
            containerId: "navTreeScrollView",
            smooth: true,
            duration: 500
        });
    }

    scrollToSection = () => {
        const {hash} = window.location;
        if (hash !== '') {
            let retries = 0;
            let imagesLoaded = 0;
            let images = document.querySelectorAll('img');
            const id = hash.replace('#', '');

            const scroll = () => {            
                for (let i = 0; i < images.length; i++) {
                    let img = new window.Image();
                    img.src = images[i].src;
                    img.onload = function() {
                        imagesLoaded++;
                    }
                }
                retries += 1;
                if (retries > 50) return;
                const element = document.getElementById(id);
                if (element && imagesLoaded >= images.length) {
                    setTimeout(() => element.scrollIntoView(), 0);
                } else {
                    setTimeout(scroll, 100);
                }
            };
            scroll();
        }
    }

    collectNodeIds(nodes) {
        return nodes.reduce((acc, n) => {
            acc.push(n.id || n.topicId);
            if (n.children?.length) {
                return [...acc, ...this.collectNodeIds(n.children)]
            }
            return acc;
        }, []);
    }

    render() {
        // If it's tiled and we're rendering ContentIndex again, we need to only pass in the exact props we need, which excludes structure and classes.
        let {classes, structure, content, runtimeConfig, ...other} = this.props;
        if (content.displayTiles) {
            return <ContentIndex runtimeConfig={runtimeConfig} structure={structure}
                                 content={content} {...other}/>
        }
        const thumbnail = content?.thumbnail || this.props.runtimeConfig.defaultSectionIcon;
        content = this.state.content || content;
        content.headers = content.headers || content.chunked_sections || [];
        const headerIds = this.collectNodeIds(content.headers);
        const hasHeaders = headerIds.length > 1;

        let currentHref = this.props.router.asPath;
        if (typeof (window) !== "undefined") {
            if (typeof this.props.runtimeConfig.permalinkFormat === "string") {
                currentHref = template(this.props.runtimeConfig.permalinkFormat, {
                    location: window.location,
                    content
                })
            } else {
                currentHref = window.location.href
            }
        }
        if (this.props.showErrorPage) {
            return <ErrorPage {...this.props}/>
        }

        const isOpenAPi = !!content.apiOperationMeta
        
        return (

            <Layout {...this.props.layoutProps}
                    className={`content-index`}
                    section={this.props.section}>
                <ContentMeta content={content}
                             meta={structure?.meta}
                             seo={runtimeConfig.seo || {}}
                             keywords={runtimeConfig.keywords}
                             title={runtimeConfig.title}
                />
                <div className={classes.contentPageContainer}>
                    <Drawer className={clsx(classes.contentPageDrawer, {
                        [classes.hidePrint]: true,
                        [classes.contentPageTocContainer]: true,
                        [classes.contentPageDrawerOpen]: this.state.drawerOpen,
                        [classes.contentPageDrawerClose]: !this.state.drawerOpen,
                    })}
                            classes={{
                                paper: clsx({
                                    [classes.contentPageDrawerOpen]: this.state.drawerOpen,
                                    [classes.contentPageDrawerClose]: !this.state.drawerOpen,
                                }),
                            }}
                            open={this.state.drawerOpen}
                            onClose={() => this.setState({drawerOpen: false})}
                            anchor="left"
                            variant={this.state.drawerOpen ? "temporary" : "persistent"}>

                        <div className={classes.contentPageSiteSectionIconWrapper}>
                            {thumbnail ?
                                <img alt={"thumbnail"}
                                     src={thumbnail}
                                     className={`no-alt ${classes.contentPageSiteSectionIcon}`}
                                /> : null}
                            <I18nMessage id={this.props.section}
                                         variant={this.props.theme.contentPageSiteSectionTitle?.variant}
                                         className={classes.contentPageSiteSectionTitle}/>
                        </div>
                        <div className={classes.contentPageTocHeader}>
                            <div className={classes.contentPageTocHeaderContainer}>

                                <I18nMessage id={"label.contents"}
                                             variant={this.props.theme.contentPageTocTitle?.variant}
                                             className={classes.contentPageTocTitle}>
                                    <span
                                        className={classes.contentPageTocSubtitle}>{this.props.section}&nbsp;</span>
                                </I18nMessage>
                                <VersionSelector versions={content.versions}
                                                 currentVersion={content.current_version}/>
                            </div>
                            <PortalFontIcon name="close"
                                            onClick={() => this.setState({drawerOpen: false})}/>
                        </div>
                        <div id="navTreeScrollView"
                             className={classes.contentPageToc}>
                            <NavTree className={classes.contentPageNavBar}
                                     contentPath={this.state.contentSubPath}
                                     tree={this.state.structure}
                                     disableLinkForNoContent={this.props.runtimeConfig?.toc?.disableLinkForNoContent}/>
                        </div>
                    </Drawer>
                    <div
                        className={`${classes.contentPageMainContainer} ${hasHeaders && 'has-chunked'}`}>
                        <div
                            className={`${classes.contentPageMainWrapper} ${hasHeaders && 'has-chunked'}`}>
                            <div
                                className={`${classes.contentPageMainHeader} ${classes.hidePrint} ${hasHeaders && 'has-chunked'}`}>
                                <div
                                    className={`${classes.contentPageHeaderBreadcrumb} ${hasHeaders && 'has-chunked'}`}>
                                    <PortalBreadcrumb
                                        section={this.props.section}
                                        breadcrumbs={content.breadcrumbs}
                                    />
                                    <ShowContents
                                        thumbnail={thumbnail}
                                        content={content}
                                        hasHeaders={hasHeaders}
                                        currentVersion={content.current_version}
                                        contentsOnClick={() => this.setState({drawerOpen: true})}
                                        chunkedOnClick={() => this.setState({chunkedOpen: true})}
                                    />
                                </div>
                                <div className={classes.contentPageShareButton}>
                                    <SocialSharing
                                        variant={this.props.theme.socialSharing?.variant}
                                        url={currentHref}
                                        subject={content.title}
                                        body={content.shortDescription || content.shortdesc}
                                        {...(this.props.theme.socialSharing?.props || {})}
                                    />
                                    <print-content
                                        output-classes={JSON.stringify(this.props.runtimeConfig.outputClasses)}
                                        content-path={this.state.contentPath}
                                        has-children={!!(content.children && content.children.length)}
                                        class={classes.contentPagePrintButton}/>
                                </div>
                            </div>
                            <div
                                className={`${classes.contentPageMain} ${hasHeaders && 'has-chunked'}`}>
                                
                                {isOpenAPi ? <OpenApiContentViewer content={content} apiDocsConfig={this.props.runtimeConfig.apiDocsConfig}/> :
                                    <RenderHtml
                                        outputClasses={this.props.runtimeConfig.outputClasses}
                                        prettify={this.props.runtimeConfig.prettifyCode}
                                        id={"printing-root"}
                                        className={`content-main ${clsx(classes.contentPageMainRendered, {
                                            [classes.contentPageMainRenderedMissing]: !content.content,
                                        })}`}
                                        onRender={(detail): any => {
                                            const el = window.location.hash && detail.querySelector(window.location.hash)
                                            el && el.scrollIntoView(this.props.runtimeConfig.toc.contentScrollConfig);
                                            this.scrollTOC();
                                            this.scrollToSection()
                                        }}
                                    >{content.content || this.context.get('message.missing-content')}</RenderHtml>
                                }
                                {hasHeaders ? (
                                    <div className={classes.contentPageChunkedSectionsWrapper}>
                                        <Drawer className={clsx(classes.contentPageDrawer, {
                                            [classes.contentPageChunkedSections]: true,
                                            [classes.drawerOpen]: this.state.chunkedOpen,
                                            [classes.contentPageDrawerClose]: !this.state.chunkedOpen,
                                        })}
                                                classes={{
                                                    paper: clsx({
                                                        [classes.drawerOpen]: this.state.chunkedOpen,
                                                        [classes.contentPageDrawerClose]: !this.state.chunkedOpen,
                                                    }),
                                                }}
                                                open={this.state.chunkedOpen}
                                                onClose={() => this.setState({chunkedOpen: false})}
                                                anchor="right"
                                                variant="persistent">
                                            <div
                                                className={classes.contentPageChunkedSectionsHeader}>
                                                <PortalFontIcon name="close"
                                                                onClick={() => this.setState({chunkedOpen: false})}
                                                                className={classes.contentPageChunkedSectionsCloseIcon}/>
                                                <I18nMessage id={"message.chunked.title"}
                                                             values={{content: {title: content.title}}}
                                                             variant={this.props.theme.contentPageChunkedSectionsTitle?.variant}
                                                             className={classes.contentPageChunkedSectionsTitle}/>
                                            </div>
                                            <SimpleTree
                                                className={classes.contentPageChunkedSectionsSimpleTree}
                                                contentPath={this.props.router.asPath}
                                                nodes={content.headers}
                                                expanded={headerIds}
                                                contentTitle={content.title}/>
                                        </Drawer>
                                    </div>
                                ) : null}
                            </div>
                            <Metadata customMetadata={content.customMetadata}
                                      locale={this.props.router.locale}
                                      standardMetadata={content.standardMetadata}
                                      disableLocaleDate={this.props.runtimeConfig.disableLocaleDate}/>
                            <StepLinks
                                className={`${classes.contentPageBottomStepLinks} ${hasHeaders && 'has-chunked'}`}
                                previous={content.previous}
                                next={content.next}/>
                        </div>
                        <RelatedLinks links={content.relatedLinks}/>
                    </div>
                </div>
            </Layout>);
    }
}

export async function getServerSideProps(args) {
    const {req, res, query, locale} = args;
    try {
        const {props} = await getContentProps(args, {
            ["Accept-Language"]: locale
        }) as any;
        const start = findStart(props.structure, props.content?.href)
        const onSiteSectionOrVersion = 'sitesection' === start?.type || 'version' === start?.type
        if ((onSiteSectionOrVersion && props.displayToc) || props.content?.redirect || (!props.content?.content && props.content?.children?.length)) {
            let firstTopic = props.content.redirect || findFirstTopicRef(start, true, props.displayToc)
            if (firstTopic && firstTopic.href !== props.content.href) {
                return {
                    redirect: {destination: `/${firstTopic.href}`, permanent: true, locale}
                }
            }
        }
        props.content.displayTiles = props.displayTiles || props.content?.outputclasses?.includes("view-tiles")
        if(!props.content.displayTiles){
            props.structure = pruneInitialTOC(props.structure, props.contentPath)
        } else {
            props.structure = removeAtDepth(findStart(props.structure, props.contentPath.replace(/^\//, '')), 2);
        }
        props.sectionVersions = null;
        assignSsoInfoProp(props, req);
        handleForceStatusCode(res)
        props.runtimeConfig = getClientSideConfig(props.runtimeConfig)
        props.runtimeConfig.seo = req.runtimeConfig.seo
        return checkAuth(req) || JSON.parse(JSON.stringify({
            props: {
                ...query,
                ...props
            },
        }));
    } catch (ex) {
        return handlePageError(ex, locale, req, res)
    }
}

export default withTheme(withStyles(contentPageStyles, {name: "ContentPage"})(withRouter(ContentPage as any)) as any)
