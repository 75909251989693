import {IHttpOperation} from "@stoplight/types";
import {
    Box,
    Flex,
    HStack,
    VStack,
    useThemeIsDark,
    NodeAnnotation,
    Heading
} from '@stoplight/mosaic';
import {DeprecatedBadge, HttpMethodColors} from "@jorsek/elements-core";
import '@jorsek/elements-core/styles-isolated.min.css';
import React from "react";
import {INavTree} from "@jorsek/content-portal-client/types";
import {I18nMessage} from "components/i18n/I18nMessage";

type MethodPathProps = { method: IHttpOperation['method']; tree: INavTree, treeTitleI18: any};

function MethodPath({method, tree, treeTitleI18}: MethodPathProps) {
    const isDark = useThemeIsDark();

    const pathElem = (
        <I18nMessage id={tree.title} values={treeTitleI18}/>
    );

    return (
        <HStack
            spacing={3}
            pl={0}
            pr={0}
            py={2}
            pt={0}
            pb={0}
            fontFamily="mono"
            display="flex"
            maxW="full"
            title={tree.title}
            justify="between"
        >
            {pathElem}
            <Box
                py={1}
                px={2.5}
                rounded="lg"
                bg={!isDark ? HttpMethodColors[method] : 'canvas-100'}
                color={!isDark ? 'on-primary' : 'body'}
                fontSize="sm"
                fontWeight="semibold"
                textTransform="uppercase"
                textDecoration="no-underline"
            >
                {method}
            </Box>
        </HStack>
    );
}

export function OpenApiNavTreeItem({
    
                                    method, tree, treeTitleI18
                                }: {

    method: string;
    tree: INavTree, treeTitleI18: any
}) {
    

    return (
        
        <div className="elementsCoreWrapper">
            <div className="elementsCoreContainer">
                <MethodPath method={method} tree={tree} treeTitleI18={treeTitleI18} />
            </div>
        </div>
    );
}