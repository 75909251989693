import React from 'react';
import {INavTree} from "@jorsek/content-portal-client/types"
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {CircularProgress, createStyles, withStyles, withTheme} from '@material-ui/core';
import clsx from 'clsx';
import {Element} from 'react-scroll';
import {findFirstTopicRef} from "../utils/tree";
import {withRouter} from "next/router";
import * as path from 'path';
import I18nMessage from "./i18n/I18nMessage";
import PortalLink from './PortalLink';
import {WithRouterProps} from 'next/dist/client/with-router';
import PortalFontIcon from './PortalFontIcon';
import NavTreeType from "./NavTreeType";
import {OpenApiNavTreeItem} from "components/OpenApiNavTreeItem";

const withNavTreeStyles = withStyles(createStyles((theme: any) => ({
    navTree: theme.navTree,
    navTreeIcon: theme.navTreeIcon,
    navTreeLoading: theme.navTreeLoading,
    navTreeLoadingSpinner: theme.navTreeLoadingSpinner,
    navTreeIconExpand: theme.navTreeIconExpand,
    navTreeIconCollapse: theme.navTreeIconCollapse,
    navTreeItem: theme.navTreeItem,
    navTreeItemSelected: theme.navTreeItemSelected,
    navTreeItemHover: theme.navTreeItemHover,
    navTreeItemHoverDisable: theme.navTreeItemHoverDisable,
    navTreeItemExpanded: theme.navTreeItemExpanded,
    navTreeItemOnHotPath: theme.navTreeItemOnHotPath,
    navTreeItemGroup: theme.navTreeItemGroup,
    navTreeItemLabel: theme.navTreeItemLabel,
    navTreeItemLabelSelected: theme.navTreeItemLabelSelected,
    navTreeItemOnHotPathCollapsed: theme.navTreeItemOnHotPathCollapsed,
    navTreeOpenApiOperationLabel: {
        display: 'inline-block',
        color: 'white',
        fontSize: '.6em',
        fontWeight: 'bold',
        marginRight: '5px',
        padding: '0 2px',
        textDecoration: 'none',
        width: '51px',
        textAlign: 'center',
        borderRadius: '4px'

    },
    navTreeOpenApiOperationLabel_method_get: {
        backgroundColor: '#0e9b71',
    },
    navTreeOpenApiOperationLabel_method_put: {
        backgroundColor: '#674ead',
    },
    navTreeOpenApiOperationLabel_method_post: {
        backgroundColor: '#0272d9',
    },
    navTreeOpenApiOperationLabel_method_delete: {
        backgroundColor: '#c71b29',
    },
    navTreeOpenApiOperationLabel_method_options: {
        backgroundColor: '#0e9b71',
    },
    navTreeOpenApiOperationLabel_method_head: {
        backgroundColor: '#0e9b71',
    },
    navTreeOpenApiOperationLabel_method_patch: {
        backgroundColor: '#0e9b71',
    },
    navTreeOpenApiOperationLabel_method_trace: {
        backgroundColor: '#0e9b71',
    },
    noUnderlineLinkOnHover: {
        "&:hover": {
            textDecoration: "none"
        }
    },
    "@global":{
        ".sl-code-viewer": {
            fontSize: "10px"
        },
        ".elementsCoreWrapper .JsonSchemaViewer .sl-text-base": {
                fontSize: "14px"
        },
        ".elementsCoreWrapper .sl-prose p": {
            fontSize: "14px !important"
        },
        ".TryItPanel .ParameterGrid label": {
            fontSize: "12px"
        },
        ".ezd-portal_nav-tree_item-selected .elementsCoreContainer .mui-typography_root": {
            "color": "white"
        },
        ".mui-tree-item_content .elementsCoreContainer .sl-text-sm": {
            "min-width": "50px",
            "text-align": "center",
            "font-size": "12px",
            "padding-left": "0",
            "padding-right": "0"
        }
    },
  
})), {name: "NavTree"});

const OpenApiTag = ({navTreeItem, treeTitleI18}: any) => {
    const openApiMethod = navTreeItem.openApiInfo?.method
    return (
        <div>
            <OpenApiNavTreeItem method={openApiMethod} tree={navTreeItem} treeTitleI18={treeTitleI18}/>
        </div>
    )
}

function disableLink(disableLinkForNoContent: boolean, treeNode: any): boolean {
    if (treeNode.type === NavTreeType.openApiTag) {
        return true
    }
    return disableLinkForNoContent ? !(treeNode.hasContent || treeNode.type === 'openApiPage') : false;
}

function getTreeItemLabel(basePath, destPath, shallow, classes, isSelected: boolean, navProps, treeTitleI18: {}, disableLinkForNoContent: boolean) {
    const isOpenApiPage = navProps.tree.type === 'openApiPage'
    return <PortalLink
        href={basePath + destPath}
        shallow={shallow}
        className={clsx({
            [classes.navTreeItemLabel]: true,
            [classes.navTreeItemLabelSelected]: isSelected,
        }) + " " + classes.noUnderlineLinkOnHover} disabled={disableLink(disableLinkForNoContent, navProps.tree)}>
        { !isOpenApiPage ?
            <I18nMessage id={navProps.tree.title} values={treeTitleI18}>
            </I18nMessage> 
            :
            <OpenApiTag classes={classes} navTreeItem={navProps.tree} />
        }
    </PortalLink>
}

const NavTreeItem = withNavTreeStyles(withRouter(({
                                                      classes,
                                                      navProps,
                                                      router,
                                                      basePath,
                                                      shallow,
                                                      disableLinkForNoContent,
                                                      ...restProps
                                                  }: any) => {
    let href = navProps.tree.href;
    if (navProps.tree.type !== "topicref") {
        href = findFirstTopicRef(navProps.tree, false).href;
    }
    let destPath = (href || "")
    let nodePath = navProps.tree.href || "";
    const isSelected = navProps.contentPath === nodePath;
    const onHotPath = navProps.expandAll || navProps.contentPath.indexOf(navProps.tree.href) > -1;
    const expanded = onHotPath || navProps.selectedTree.indexOf(navProps.tree.href);
    const treeTitleI18 = {};
    treeTitleI18[`${navProps.tree.title}`] = navProps.tree.title;
    
    return (
        <Element name={navProps.tree.href} id={navProps.tree.href}
                 className={`${navProps.tree.type} element`}>
            <TreeItem
                onMouseOver={(evt: any) => {
                    const root = evt.target.classList.contains("treeitem") ? evt.target : evt.target.closest(".treeitem");
                    root?.classList.add(classes.navTreeItemHover);
                }}
                onMouseOut={(evt: any) => {
                    const root = evt.target.classList.contains("treeitem") ? evt.target : evt.target.closest(".treeitem");
                    root?.classList.remove(classes.navTreeItemHover);
                }}
                onLabelClick={()=> navProps.onLabelClick(destPath)}
                nodeId={navProps.tree.href}

                label={getTreeItemLabel(basePath, destPath, shallow, classes, isSelected, navProps, treeTitleI18, disableLinkForNoContent)}
                classes={{
                    expanded: clsx({
                        [classes.navTreeItemExpanded]: onHotPath && expanded
                    }),
                    content: clsx({
                        [classes.navTreeItem]: true,
                        ["treeitem"]: true,
                        [`depth-${navProps.depth}`]: true,
                        [classes.navTreeItemOnHotPath]: !isSelected && onHotPath,
                        [classes.navTreeItemSelected]: isSelected
                    }),
                    group: classes.navTreeItemGroup,
                    root: clsx({
                        [classes.navTreeItemOnHotPathCollapsed]: onHotPath && !expanded,
                        [classes.navTreeItem]: true
                    }),
                    label: classes.navTreeItemHoverDisable
                }}
                {...restProps}
            >
                {navProps.tree.children ? navProps.tree.children.map(child => <NavTreeItem
                    key={navProps.depth + child.href}
                    basePath={basePath}
                    shallow={shallow}
                    disableLinkForNoContent={disableLinkForNoContent}
                    navProps={{
                        ...navProps,
                        tree: child,
                        depth: navProps.depth + 1
                    }}/>): null}
            </TreeItem>
        </Element>
    )
}))

NavTreeItem.defaultProps = {
    navProps: {
        depth: 0,
        tree: {
            children: [],
            href: ""
        }
    },
    basePath: "",
    shallow: true
}

export interface INavTreeComponentProps extends WithRouterProps {
    expandAll?: boolean;
    tree: INavTree;
    loading?: boolean;
    shallowLinks?: boolean;
    contentPath?: string;
    section?: string;
    basePath?: string;
    className?: string;
    classes?: any;
    theme?: any;
    disableLinkForNoContent?: boolean
}

class NavTree extends React.Component<INavTreeComponentProps> {

    static defaultProps = {
        expandAll: false,
        basePath: "",
        section: "",
        shallowLinks: true
    }

    state = {
        expanded: [],
        contentPath: null,
    }
    
    getExpandedList = (thisPath)=>{
        if (!thisPath) return []
        return [
            thisPath,
            ...this.makeSubPaths(thisPath),
            ...(this.props.expandAll ? this.getPaths(this.props.tree) : [])
        ];
    }

    makeSubPaths(thisPath = "") {
        const results = [];
        while (thisPath.lastIndexOf("/") > -1) {
            thisPath = thisPath.slice(0, thisPath.lastIndexOf("/"))
            results.push(thisPath || "/");
        }
        return results;
    }

    getPaths = ({
                    href,
                    children
                }) => (children && children.length) ? [...this.makeSubPaths(href), ...this.getPaths(children[0])] : this.makeSubPaths(href);
    getContentPath = () => {
        let {section, contentPath} = this.props.router?.query as any || {};
        contentPath = contentPath || this.props.contentPath;
        section = section || this.props.section;
        if (contentPath) {
            section = path.join(section, Array.isArray(contentPath) ? contentPath.join('/') : contentPath);
        }
        return section;
    }

    componentDidUpdate(prevProps) {
        if (this.props.expandAll !== prevProps.expandAll) {
            if (this.props.expandAll) {
                const expanded = this.getPaths(this.props.tree);
                this.setState({expanded})
            } else {
                this.setState({expanded: this.getExpandedList(this.getContentPath())})
            }
        }
    }
    updateExpanded = ()=> {
        this.setState({expanded: this.getExpandedList(this.getContentPath())});
    }

    componentDidMount() {
        this.updateExpanded();
        this.props.router?.events.on("routeChangeComplete", this.updateExpanded);
    }
    
    componentWillUnmount() {
        this.props.router?.events.off("routeChangeComplete", this.updateExpanded);
    }

    render() {
        const {className} = this.props;
        if (!this.props.contentPath || !this.props.tree) {
            return (
                <div className={this.props.classes.navTreeLoading}>
                    <CircularProgress
                        className={this.props.classes.navTreeLoadingSpinner}
                        {...this.props.theme.navTree?.loadingSpinner}/>
                </div>
            )
        }

        return <TreeView
            className={className}
            defaultExpandIcon={<PortalFontIcon name={this.props.theme.navTree?.iconExpand}
                                               className={`${this.props.classes.navTreeIcon} ${this.props.classes.navTreeIconExpanded}`}/>}
            defaultCollapseIcon={<PortalFontIcon name={this.props.theme.navTree?.iconCollapse}
                                                 className={`${this.props.classes.navTreeIcon} ${this.props.classes.navTreeIconCollapsed}`}/>}
            expanded={typeof window === "undefined" ? this.getExpandedList(this.getContentPath()) : this.state.expanded}
            onNodeToggle={(_evt, expanded)=>{
                this.setState({expanded})
            }}
        >
            {this.props.tree.children ? this.props.tree.children.map((child) => (
                <NavTreeItem key={child.href}
                             basePath={this.props.basePath}
                             shallow={this.props.shallowLinks}
                             disableLinkForNoContent={this.props.disableLinkForNoContent}
                             navProps={{
                                 onLabelClick: (id) => {
                                     this.setState({expanded: this.getExpandedList(id)})
                                 },
                                 expandAll: this.props.expandAll,
                                 tree: child,
                                 selectedTree: this.state.expanded,
                                 contentPath: this.getContentPath(),
                                 depth: 1
                             }}
                />)): null}
        </TreeView>
    }
}

// @ts-ignore
export default withRouter(withTheme(withNavTreeStyles(NavTree))) as any;