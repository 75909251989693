import {transformOasToServiceNode} from '@jorsek/elements';
import {
    InlineRefResolverProvider,
    ParsedDocs,
    useBundleRefsIntoDocument,
    useParsedValue,
    withMosaicProvider,
    withPersistenceBoundary,
    withQueryClientProvider,
} from '@jorsek/elements-core';
import React from 'react';
import {useQuery} from 'react-query';
import HerettoDeployWindowObj, {ClientEvent} from "../HerettoDeployWindowObj";

let lastPathName = undefined;

/**
 * Displays an API operation using the Stoplight UI. 
 * The API operation is found based on the operationId, or the path and method.
 */
const StoplightElementViewer = ({specUrl, operationId, path, method, apiDocsConfig}) => {
    if (!operationId && !path && !method) {
        console.warn('StoplightElementViewer -- No operationId, path, or method provided');
    }
    const apiDescriptionDocument = undefined;
    const apiDescriptionUrl = specUrl
    const {data: fetchedDocument, error} = useQuery(
        [apiDescriptionUrl],
        () =>
            fetch(apiDescriptionUrl).then(res => {
                if (res.ok) {
                    return res.text();
                }
                throw new Error(`Unable to load description document, status code: ${res.status}`);
            }),
        {
            enabled: apiDescriptionUrl !== '' && !apiDescriptionDocument,
            cacheTime: 1000 * 60 * 60, // Cache for 1 hour
        },
    );
    const document = apiDescriptionDocument || fetchedDocument || '';
    const parsedDocument = useParsedValue(document);
    const bundledDocument = useBundleRefsIntoDocument(parsedDocument, {baseUrl: apiDescriptionUrl});
    const serviceNode = React.useMemo(() => {
        return transformOasToServiceNode(bundledDocument);
    }, [bundledDocument]);

    const findNode = (serviceNode) => {
        return serviceNode.children.find(child => {
            if (operationId) {
                return child.data?.iid == operationId;
            } else {
                return child.data?.path === path && child.data?.method === method;
            }
        })
    }

    const node = serviceNode ? findNode(serviceNode) : undefined;

    const hideTryIt = false;
    const hideExport = true;

    const layoutOptions = React.useMemo(
        () => ({hideTryIt: hideTryIt, hideExport: hideExport}),
        [hideTryIt, hideExport],
    );
    const pd =
        node === undefined ? (
            <div>Loading...</div>
        ) : (
            <InlineRefResolverProvider document={parsedDocument}>
                <ParsedDocs
                    key={node?.uri}
                    uri={node?.uri}
                    // @ts-ignore
                    node={node}
                    nodeTitle={node?.name}
                    layoutOptions={layoutOptions}
                    tryItCorsProxy={apiDocsConfig && apiDocsConfig.tryItCorsProxy ? window.location.origin + "/api/docsproxy/" : undefined}
                />
            </InlineRefResolverProvider>
        );
    if (node != undefined && lastPathName != node.uri) {
        lastPathName = node.uri;
        setTimeout(() => {
            return HerettoDeployWindowObj.triggerEvent(ClientEvent.pageUpdate);
        });
    }
    return pd;
}
export default withPersistenceBoundary(withQueryClientProvider(withMosaicProvider(StoplightElementViewer)))