import React from "react";
import {createStyles, withStyles} from "@material-ui/core";
import I18nMessage from "./i18n/I18nMessage";
import PortalLink from "./PortalLink";

const relatedLinksStyles = createStyles((theme: any) => ({
    relatedLinks: theme.relatedLinks,
    relatedLinksTitle: theme.relatedLinksTitle,
    relatedLinksList: theme.relatedLinksList,
    relatedLinksListItem: theme.relatedLinksListItem,
    relatedLinksListItemHref: theme.relatedLinksListItemHref
}));

interface IRelatedLinksProps {
    links?: any[],
    classes?: any;
}

const RelatedLinks = ({links, classes}: IRelatedLinksProps) => {
    return (
        <>
            {links.length !== 0 ? (
                <div className={classes.relatedLinks}>
                    <I18nMessage id="label.related-articles" className={classes.relatedLinksTitle}/>
                    <ul className={classes.relatedLinksList}>
                        {links.map((link) => (
                            <li className={classes.relatedLinksListItem} key={link.href}>
                                <I18nMessage Component={PortalLink}
                                             scroll={true}
                                             shallow={true}
                                             className={classes.relatedLinksListItemHref}
                                             href={link.href}
                                             id={link.title}/>
                            </li>
                        ))}
                    </ul>
                </div>) : null}
        </>
    )
}


RelatedLinks.defaultProps = {
    classes: {} as any,
    links: [] as any
}

export default withStyles(relatedLinksStyles, {name: "RelatedLinks"})(RelatedLinks)