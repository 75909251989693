import React from "react";
import StoplightElementViewer from "./StoplightElementViewer";
import { ApiOperationMeta } from "./types";



const OpenApiContentViewer = ({content, apiDocsConfig}) => {
    if (typeof window == 'undefined') {
        return null
    }
    
    const isOpenAPi = !!content.apiOperationMeta
    let openApiPath = undefined
    let openApiMethod = undefined
    let openApiId = undefined
    let operationId = undefined
    if (isOpenAPi) {
        const apiMeta: ApiOperationMeta = content.apiOperationMeta
        openApiId = apiMeta.apiSpecificationId
        openApiPath = apiMeta.path
        openApiMethod = apiMeta.method
        operationId = apiMeta.operationId
    }
    
    const specUrl =  `/api/api-specification/${openApiId}`
    
        return (
        <div className="elementsCoreWrapper">
            <div className="elementsCoreContainer">
                <StoplightElementViewer specUrl={specUrl} operationId={operationId} path={openApiPath} method={openApiMethod} apiDocsConfig={apiDocsConfig}/>
            </div>
        </div>
        )
} 
export default OpenApiContentViewer