let MAX_DEPTH:number = 2;

interface Node {
    children?: Node[]
}
//DFS of the structure to find the first topicRef that we see.
export const findFirstTopicRef = (next: Readonly<any>, checkView = true, skipRoot = false) => {
    if (!skipRoot) {
        let isTopicHead = next.type === "topichead";
        let isTopic = next.type === "topicref";
        let isVersion = next.type === "version";
        let noChildren = !next.children || !next.children.length
        if (isTopicHead && noChildren) return next;
        if (isTopic || isVersion || noChildren) return next;
    }
    let stop = !next || (checkView && next.outputclasses?.includes("view-tiles"))
    if (stop) return next;
    for (let i = 0; i < next.children?.length; i++) {
        let nextChildNode = next.children[i];
        if (nextChildNode.type === "topicref") {
            return nextChildNode;
        } else {
            let possibleNode = findFirstTopicRef(nextChildNode)
            if (possibleNode.type === "topicref") {
                return possibleNode;
            }
        }
    }
    return next;
}
export const removeAtDepth = (node: Readonly<Node>, target, currentDepth = 0)=>{
    const newNode = Object.assign({}, node) as any;
    if (target === currentDepth) {
        newNode.children = []
    } else if (node && node.children) {
        newNode.children = node.children.map((child)=> removeAtDepth(child, target, currentDepth + 1))
    }
    return newNode;
}
//DFS of the structure to find the given href.
export const findStart = (next: Readonly<Node>, reference, key = "href") => {
    if (next && next[key] === reference) {
        return next;
    }
    if (!next || !next.children) return;
    for (let i = 0; i < next.children.length; i++) {
        const nextChildRef = findStart(next.children[i], reference, key);
        if (nextChildRef) {
            return nextChildRef
        }
    }
}

export const pruneInitialTOC = (node: Readonly<Node>,
                                contentPath: string,
                                isTopLevel: boolean = true,
                                depthMatched: number = 0) => {
    if (!node || !contentPath) return {
        ...node,
        children: isTopLevel ? node.children?.map((child: Readonly<Node>) => {
            return pruneInitialTOC(child, contentPath, false);

        }) : []  //if contentPath is empty, then we only render the immediate children under root, and zero out all of their descendants
    };

    return {
        ...node,
        children: node.children?.filter((child) => child &&
            //limit the depth of children after the match in the tree with MAX_DEPTH
            (isTopLevel || (child["href"] && (depthMatched <= MAX_DEPTH) && (child["href"].startsWith(contentPath) || contentPath.startsWith(child["href"])))))
            .map((child: Readonly<Node>) => {
                    let depth = depthMatched;
                    if (child["href"] && child["href"].startsWith(contentPath)) {
                        depth++;
                    }
                    return pruneInitialTOC(child, contentPath, false, depth);
                }
            )
    }
}


