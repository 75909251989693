import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import {createStyles, withStyles} from '@material-ui/core';
import {withRouter} from "next/router";
import I18nMessage from "./i18n/I18nMessage";
import {WithRouterProps} from 'next/dist/client/with-router';
import clsx from "clsx";

const withSimpleTreeStyles = withStyles(createStyles((theme: any) => ({
    simpleTree: theme.simpleTree,
    simpleTreeItem: theme.simpleTreeItem,
    simpleTreeItemSelected: theme.simpleTreeItemSelected,
    simpleTreeContentTitle: theme.simpleTreeContentTitle,
})), {name: "SimpleTree"});

interface ISimpleTreeItem {
    classes: any,
    className: string;
    title: string;
    id: string;
    depth: number;
    node: any;
    onSelect: any;
    selected: string;
}
const SimpleTreeItem = withSimpleTreeStyles(({
                                                 classes,
                                                 className,
                                                 title,
                                                 id,
                                                 depth,
                                                 node,
                                                 onSelect,
                                                 selected
                                             }: ISimpleTreeItem) => {
    id = id || "#";
    return (
        <TreeItem
            className={`${className || ''} ${id}`}
            onClick={() => onSelect(id)}
            nodeId={id}
            label={(<a href={`${id.startsWith("#") ? "" : "#"}${id}`}
                       className={clsx({
                           [id]: true,
                           [`depth-${depth}`]: true,
                           [classes.simpleTreeItem]: true,
                           [classes.simpleTreeItemSelected]: selected === id,
                       })}>
                <I18nMessage
                    id={title}
                    textValue={title}
                    color="textPrimary"
                /></a>)}
        >
            {node.children?.map(child => {
                const id = child.id || child.topicId;
                return <SimpleTreeItem key={depth + id}
                                className={className}
                                title={child.title}
                                depth={depth + 1}
                                id={id}
                                node={child}
                                onSelect={onSelect}
                                selected={selected}/>
            })}
        </TreeItem>
    )
});

SimpleTreeItem.defaultProps = {
    node: {},
    onSelect: () => {
    }
}

export interface INavTreeComponentProps extends WithRouterProps {
    nodes: any[];
    expanded: string[];
    onSelect: any;
    contentPath?: string;
    className?: string;
    itemClassName?: string;
    classes?: any;
    contentTitle?: string;
    selectedChunkedSection?: any;
}

const getHash = (): string => {
    return (typeof window !== "undefined") && window.location.hash.replace("#", "") || "#";
}

const getRootElement = () => {
    return (typeof window !== "undefined") && document.getElementById("scroll-root");
}

const getHeader = () => {
    return (typeof window !== "undefined") && document.querySelector("#scroll-root > header");
}

const getPrintRoot = () => {
    return (typeof window !== "undefined") && document.getElementById("printing-root");
}


class SimpleTree extends React.Component<INavTreeComponentProps, {}> {
    state = {
        contentTitle: null,
        selected: getHash(),
        nodes: [],
    }
    static defaultProps = {
        className: "",
        itemClassName: "",
        expanded: [],
        onSelect: () => {
        }
    }

    /**
     * Select the header that is currently scrolled into view. Operates in 2 modes, 
     * depending on if the page has a fixed header. If the page has a fixed header, 
     * the header is included in the calculation. 
     * 
     * To determine the currently scrolled header, it finds first header that is below the top of the page, 
     * and selects the previous header.
     * The top of the page is defined by the top of the content area, which is calculated by
     * scrollTop + printRoot.getBoundingClientRect().top
     */
    selectHeaderScrolledIntoView = () => {
        const scrollRoot = getRootElement();
        if (scrollRoot.scrollTop === 0) {
            this.setState({selected: "#"});
        } else {
            const printRoot = getPrintRoot();
            let scrolledArticle;
            if (this.hasFixedHeader()) {
                scrolledArticle = this.calculateScrolledArticleForFixedHeader(scrollRoot, printRoot);
            } else {
                scrolledArticle = this.calculateScrolledArticleForNonFixedHeader(printRoot)
            }
            if (scrolledArticle) {
                this.setState({selected: scrolledArticle.id});
            }
        }
    };

    

    private hasFixedHeader() {
        return 'fixed' === window.getComputedStyle(getHeader()).position
    }

    private getHeaders(printRoot: HTMLElement) {
        return Array.from(printRoot.querySelectorAll(`#${this.props.expanded.join(',#')}`));
    }

    private calculateScrolledArticleForFixedHeader(scrollRoot: HTMLElement, printRoot: HTMLElement) {
        const top = scrollRoot.scrollTop + printRoot.getBoundingClientRect().top
        const headers = this.getHeaders(printRoot)
        let scrolledArticle = headers[headers.length - 1]; // handle the last header. 
        for (let i = 1; i < headers.length; i++) {
            const boundingRectTop = headers[i].getBoundingClientRect().top;
            const header = headers[i];
            if (boundingRectTop > top) {
                scrolledArticle = headers[i - 1];
                break;
            }
        }
        return scrolledArticle
    }


    private calculateScrolledArticleForNonFixedHeader(printRoot: HTMLElement) {
        const headers = this.getHeaders(printRoot)
        let scrolledArticle = headers[headers.length - 1] // handle the last header.
        for (let i = 1; i < headers.length; i++) {
            const boundingRectTop = headers[i].getBoundingClientRect().top
            if (boundingRectTop > 1) {
                scrolledArticle = headers[i - 1]
                break
            }
        }
        return scrolledArticle
    }

    updateNodes() {
        const {nodes, contentTitle} = this.props;
        if (nodes !== this.state.nodes) {
            this.setState({
                contentTitle,
                nodes
            });
        }
    }

    componentDidUpdate() {
        this.updateNodes();
    }

    componentDidMount() {
        this.updateNodes();
        const root = getRootElement();
        root.addEventListener("scroll", this.selectHeaderScrolledIntoView, false);
    }

    componentWillUnmount() {
        const root = getRootElement();
        root.removeEventListener("scroll", this.selectHeaderScrolledIntoView, false);
    }

    render() {
        const {className, classes, itemClassName, onSelect, expanded} = this.props;
        // @ts-ignore
        return (<TreeView
            className={`${className || ''} ${classes.simpleTree}`}
            expanded={expanded}
        >
            {this.props.nodes.map((child) => {
                const id = (child.id || child.topicId);
                return (<SimpleTreeItem key={id + child.title}
                                 title={child.title}
                                 id={id}
                                 onSelect={(topicId) => {
                                     this.setState({selected: topicId});
                                     onSelect(topicId)
                                     if (topicId === "#") {
                                         const scrollRoot = document.getElementById("scroll-root");
                                         scrollRoot && typeof(scrollRoot.scrollTo) === "function" && scrollRoot.scrollTo({
                                             top: 0,
                                             left: 0
                                         });
                                     }
                                 }}
                                 className={itemClassName || ""}
                                 node={child}
                                 depth={0}
                                 selected={this.state.selected}/>);  
            })}
        </TreeView>);
    }


    
}

// @ts-ignore
export default withRouter(withSimpleTreeStyles(SimpleTree));