import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';
import I18nMessage from "./i18n/I18nMessage";
import PortalFontIcon from "./PortalFontIcon";
import VersionSelector from "./VersionSelector";
import StepLinks from "./StepLinks";


const showContentsStyles = createStyles((theme: any) => ({
    stepLinksTop: theme.stepLinksTop,
    showContentsWrapper: theme.showContentsWrapper,
    showContents: theme.showContents,
    showContentsLink: theme.showContentsLink,
    showContentsLinkIcon: theme.showContentsLinkIcon,
    showContentsSections: theme.showContentsSections,
    showContentsParentTitle: theme.showContentsParentTitle,
    showContentsTitle: theme.showContentsTitle,
    showContentsTitleText: theme.showContentsTitleText,
    showContentsTitleBreadcrumb: theme.showContentsTitleBreadcrumb,
    showContentsIcon: theme.showContentsIcon,
    showContentsImage: theme.showContentsImage,
}))

const ShowContents = ({content, classes, hasHeaders, currentVersion, contentsOnClick, chunkedOnClick, thumbnail}: any)=>{
    return (
        <div className={classes.showContents}>
            <div className={classes.showContentsWrapper}>
                <I18nMessage id={"label.show-contents"}
                             className={classes.showContentsLink}
                             onClick={contentsOnClick}>
                    <PortalFontIcon className={classes.showContentsLinkIcon} name={classes.showContentsLinkIcon.name || "list_alt"}/>
                </I18nMessage>
                <VersionSelector versions={content.versions}
                                 currentVersion={currentVersion}/>
                {hasHeaders ? (
                    <I18nMessage className={classes.showContentsSections}
                                 id={"label.show-sections"}
                                 onClick={chunkedOnClick}>
                        <PortalFontIcon name="list_alt"/>
                    </I18nMessage>
                ) : null}
            </div>
            <div className={classes.showContentsTitle}>
                <StepLinks className={classes.stepLinksTop}
                           previous={content.previous} 
                           next={content.next}>
                    <div className={classes.showContentsTitleText}>
                        <I18nMessage className={classes.showContentsTitleBreadcrumb}
                                     id={(content.breadcrumbs && content.breadcrumbs.length > 1) ? content.breadcrumbs[1].title : ""}>
                            {thumbnail? (<img alt={"thumbnail"} src={thumbnail} className={`${classes.showContentsIcon} ${classes.showContentsImage}`}/>): null}
                        </I18nMessage>
                        <I18nMessage id={content.parent && content.parent.title}
                                     className={classes.showContentsParentTitle}/>
                    </div>
                </StepLinks>
            </div>
        </div>
    )
}

export default withStyles(showContentsStyles, { name: "ShowContents" })(ShowContents)