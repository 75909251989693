import React from "react";
import PortalLink from "./PortalLink";
import {createStyles, withStyles} from "@material-ui/core";
import { withRouter } from "next/router";
import PortalFontIcon from "./PortalFontIcon";
import {WithRouterProps} from "next/dist/client/with-router";
import path from "path";

const stepLinksStyles = createStyles((theme: any) => ({
    hidePrint: theme.hidePrint,
    stepLinks: theme.stepLinks,
    stepLinkIcon: theme.stepLinkIcon
}));

interface IStepLinkProps extends WithRouterProps {
    previous?: any;
    next?: any;
    children?: any;
    classes?: any;
    className?: string;
}

const StepLinks = ({previous, next, children = null, router, classes, className}: IStepLinkProps) => {
    let prevHref = "", nextHref = "", asPath;
    asPath = router && router.asPath;
    if (previous) {
        let prevTo = previous.contentHref || previous.href;
        prevHref = prevTo && path.join('/', prevTo)
    }
    if (next) {
        let nextTo = next.contentHref || next.href;
        nextHref = nextTo && path.join('/', nextTo)
    }
    return (
        <div className={`${classes.stepLinks} ${className}`}>
                <PortalLink href={prevHref}
                            rel={"prev"}
                            name={"previous"}
                            disabled={!prevHref || (prevHref === asPath)}
                            className={classes.stepLinkIcon}>
                    <PortalFontIcon className={"prev-icon link-icon"} name="chevron_left" fontSize="large"/>
                </PortalLink>
            {children}
                <PortalLink href={nextHref}
                            rel={"next"}
                            name={"next"}
                            disabled={!nextHref || (nextHref === asPath)}
                            className={classes.stepLinkIcon}>
                    <PortalFontIcon className={"next-icon link-icon"}  name="chevron_right" fontSize="large"/>
                </PortalLink>
        </div>
    );
}

export default withRouter(withStyles(stepLinksStyles, {name:"StepLinks"})(StepLinks));